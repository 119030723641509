import { combineReducers } from '@reduxjs/toolkit';
import loginReducer from './loginSlice';
import userReducer from './userSlice';
import dropdownReducer from './dropdownSlice';
import propertyReducer from './propertySlice';

const reducers = combineReducers({
  login: loginReducer,
  userDetails: userReducer,
  dropdown: dropdownReducer,
  property: propertyReducer,
});

export default reducers;
