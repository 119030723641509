import requestServer from '../request-server';

let internals = {};

internals.getSavedProperties = function (data) {
  return requestServer(
    'v1/user/wishlist',
    data,
    'GET',
    {},
    'application/json',
    true
  );
};

internals.getSavedPropertyByID = function (data) {
  return requestServer(
    `v1/user/wishlist/${data.id}/details`,
    '',
    'GET',
    {},
    'application/json',
    true
  );
};

internals.getRecomendedPropertyByID = function (data) {
  return requestServer(
    `v1/user/recommendation/${data.id}/details`,
    '',
    'GET',
    {},
    'application/json',
    true
  );
};

internals.getListingProperties = function (data) {
  return requestServer(
    'v1/user/properties',
    data,
    'GET',
    {},
    'application/json',
    true
  );
};

internals.getListingPropertiesByID = function (data) {
  return requestServer(
    `v1/user/property/${data.id}/details`,
    '',
    'GET',
    {},
    'application/json',
    true
  );
};

internals.getRecommendations = function (data) {
  return requestServer(
    `v1/user/recommendations`,
    data,
    'GET',
    {},
    'application/json',
    true
  );
};

internals.getDropDownValues = function (data) {
  return requestServer(
    `v1/api/recommendation/dropdown_values`,
    data,
    'GET',
    {},
    'application/json',
    true
  );
};

internals.wishList = function (data, method) {
  return requestServer(
    `v1/user/wishlist`,
    data,
    method,
    {},
    'application/json',
    true
  );
};

internals.addProperty = function (data) {
  return requestServer(
    'v1/user/property/add',
    data,
    'POST',
    {},
    'multipart/form-data',
    true
  );
};

export default internals;
