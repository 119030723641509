import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    userDetails: {},
    userName:'',
    isExistingUser:false,
    selectedChatID:false,
    feedback:{},
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserDetails: (state,action) => {
      state.userDetails  = {...state.userDetails,...action.payload};
    },
    setUserName: (state,action) => {
      state.userName  = action.payload;
    },
    setIsExistingUser: (state,action) => {
      state.isExistingUser  = action.payload;
    },
    setSelectedChatID: (state,action) => {
      state.selectedChatID  = action.payload;
    },
    resetSelectedID:(state)=>{
     state.selectedChatID =  false;
    },
    setFeedBack: (state,action) => {
      state.feedback = {
        ...state.feedback,
        [action.payload.messageId]: action.payload.feedbackType,
      };
    },
    clearUserData: (state) => {
      state.isExistingUser  = false;
      state.userDetails = {};
      state.userName ='';
      state.selectedChatID  = false;
      state.feedback = {};
    },
  },
})

export const  userActions  = userSlice.actions

export default userSlice.reducer
