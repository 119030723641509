export const HOME = '/';
export const LOGIN = '/login';
export const VERIFY_OTP = '/verify-otp';
export const OTP_SUCCESS = '/otp-success';
export const CHAT_WITH_AIRA = '/chat-with-aira';
export const UPDATE_DATAILS = '/update-details';
export const MESSAGES = '/messages';
export const DASHBOARD = '/dashboard';
export const ADD_PROPERTY = '/add-property';
export const FOUR_0_FOUR = '*';
