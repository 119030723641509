import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import "./propertyDetail.css";
import {
  Button,
  Skeleton,
} from "@mui/material";
import VIEWICON from "../Assets/images/view-icon.png";
import SUCCESSTICK from "../Assets/images/success-tick.png";
import PROFILEPIC from "../Assets/images/profile.png";
import STAR from "../Assets/images/Star.png";
import CLOSE from "../Assets/images/close-icon.png";
import PDFICON from "../Assets/images/pdf.png";
import { useEffect, useState } from "react";
import apis from "../Services/api-services";
import { numDifferentiation } from "../Utils/helperUtils";

export default function ProperyDetail(props) {
  const { toggleDrawer, open, selectedPropertyID, from } = props;
  const [selectedproperty, setSelectedProperty] = useState({});
  const [loading, setLoading] = useState(false);
  const [activeImg,setActiveImg] =  useState('')

  useEffect(() => {
    if (selectedPropertyID) {
      setLoading(true);
      if (from === "saved") {
        apis.property
          .getSavedPropertyByID({ id: selectedPropertyID })
          .then((response) => {
            setLoading(false);
            const [property] = response?.data?.property;
            if(property?.files && property?.files.length> 0){
              setActiveImg(property?.files[0])
            }
            setSelectedProperty(property);
          })
          .catch((err) => {
            setLoading(false);
            console.log("Error fetching property by ID", err);
          });
      } else if (from === "home") {
        apis.property
          .getRecomendedPropertyByID({ id: selectedPropertyID })
          .then((response) => {
            setLoading(false);
            const [property] = response?.data?.property;
            if(property?.files && property?.files.length> 0){
              setActiveImg(property?.files[0])
            }
            setSelectedProperty(property);
          })
          .catch((err) => {
            setLoading(false);
            console.log("Error fetching property by ID", err);
          });
      } else {
        apis.property
          .getListingPropertiesByID({ id: selectedPropertyID })
          .then((response) => {
            setLoading(false);
            const [property] = response?.data?.property;
            if(property?.files && property?.files.length> 0){
              setActiveImg(property?.files[0])
            }
            setSelectedProperty(property);
          })
          .catch((err) => {
            setLoading(false);
            console.log("Error fetching property by ID", err);
          });
      }
    }
  }, [selectedPropertyID]);

  const handlePdfClick = (pdf) => {
    window.open(pdf, "_blank");
  };

  return (
    <div>
      <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
        <Box
          id="property-detail"
          sx={{ width: 645 }}
          role="presentation"
          className="property-detail-container"
        >
          <span className="property-detail-close" onClick={toggleDrawer(false)}>
            <img
              src={CLOSE}
              alt="close"
              className="property-detail-close-icon"
            />
          </span>
          {loading ? (
            <Box sx={{ margin: 3 }}>
              <Skeleton height={300} />
              <Skeleton height={200} />
              <Skeleton height={300} />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </Box>
          ) : (
            <div className="property-detail-content" id="property-detail">
              {(selectedproperty?.files && selectedproperty?.files.length > 0) && <div className="property-images">
                <div className="property-active-image-container">
                  <img
                    src={activeImg?.file_url}
                    alt="active-image"
                    className="active-property-img"
                  />
                </div>
                {selectedproperty?.files.length > 1 && <div className="property-images-list">
                  {
                    selectedproperty?.files.map((item,index)=>(
                    <div key={item.file_id} className="property-image-box" onClick={()=>setActiveImg(item)}>
                    <img src={item.file_url} title={item.file_name} alt="property-img" className="property-img" />
                  </div>
                    ))
                  }
                </div>}
              </div>}
              <div className="property-info">
                <div className="property-info-stats">
                  <div className="property-views-info">
                    <img src={VIEWICON} alt="view" className="views-icon" />
                    <span className="property-views-text">{selectedproperty?.views} Views</span>
                  </div>
                  <span className="property-value">
                    {numDifferentiation(selectedproperty?.property_title)}
                  </span>
                </div>
                <div className="property-info-details">
                  <div className="property-info-details-list">
                    <span className="property-info-title">
                      {selectedproperty?.property_title}{" "}
                      <img
                        src={SUCCESSTICK}
                        alt="view"
                        className="success-tick-icon"
                      />
                    </span>
                    <span className="property-info-location">
                      {selectedproperty?.locality} ,{" "}
                      {selectedproperty?.property_address} ,{" "}
                      {selectedproperty?.city}
                    </span>
                    <span className="property-info-subtitle">
                    {selectedproperty?.property_type}  | {selectedproperty?.furnish_type} | {selectedproperty?.built_up_area} sq.ft.
                    </span>
                  </div>
                  <div className="property-info-postedBy">
                    <span className="property-info-postedby-text">
                      Posted by:
                    </span>
                    <div className="property-info-agent-details">
                      <div className="agent-profile-details">
                        <img
                          src={PROFILEPIC}
                          alt="profile"
                          className="agent-profile-icon"
                        />
                      </div>
                      <div className="agent-details">
                        <span className="agent-name">
                          {selectedproperty?.created_by}{" "}
                          <img
                            src={SUCCESSTICK}
                            alt="profile"
                            className="agent-verified-icon"
                          />
                        </span>
                        <span className="agent-rating">
                          <img
                            src={STAR}
                            alt="profile"
                            className="agent-star"
                          />{" "}
                          {selectedproperty?.agent_ratings} |{" "}
                          <span className="agent-reviews">{selectedproperty?.agent_reviews} Reviews</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="property-info-rooms">
                  <div className="property-rooms">
                    <span className="property-room-count">
                      {selectedproperty?.bedroom_count || 0}
                    </span>
                    <span className="property-room-type">Bedrooms</span>
                  </div>
                  <div className="property-rooms">
                    <span className="property-room-count">
                      {selectedproperty?.bathroom_count || 0}
                    </span>
                    <span className="property-room-type">Bathrooms</span>
                  </div>
                  <div className="property-rooms">
                    <span className="property-room-count">
                      {selectedproperty?.balcony_count || 0}
                    </span>
                    <span className="property-room-type">Balcony</span>
                  </div>
                </div>
              </div>
              <div className="property-overview property-box-border">
                <div className="property-overview-title">Overview</div>
                {selectedproperty?.bedroom_count && <div className="property-overview-items">
                  <span className="property-overview-label">Bedrooms</span>
                  <span className="property-overview-value">
                    {selectedproperty?.bedroom_count}
                  </span>
                </div>}
                {selectedproperty?.bathroom_count && <div className="property-overview-items">
                  <span className="property-overview-label">Bathrooms</span>
                  <span className="property-overview-value">
                    {selectedproperty?.bathroom_count}
                  </span>
                </div>}
                {selectedproperty?.balcony_count && <div className="property-overview-items">
                  <span className="property-overview-label">Balconies</span>
                  <span className="property-overview-value">
                    {selectedproperty?.balcony_count}
                  </span>
                </div>}
                {selectedproperty?.property_type && <div className="property-overview-items">
                  <span className="property-overview-label">Property Type</span>
                  <span className="property-overview-value">
                    {selectedproperty?.property_type}
                  </span>
                </div>}
                {selectedproperty?.furnish_type && <div className="property-overview-items">
                  <span className="property-overview-label">Furnishing</span>
                  <span className="property-overview-value">
                    {selectedproperty?.furnish_type}
                  </span>
                </div>}
                {selectedproperty?.rent_amount && <div className="property-overview-items">
                  <span className="property-overview-label">Rent Amount</span>
                  <span className="property-overview-value">
                    {selectedproperty?.rent_amount}
                  </span>
                </div>}
                {selectedproperty?.built_up_area && <div className="property-overview-items">
                  <span className="property-overview-label">Built-up Area</span>
                  <span className="property-overview-value">
                    {selectedproperty?.built_up_area}
                  </span>
                </div>}
                {selectedproperty?.carpet_area && <div className="property-overview-items">
                  <span className="property-overview-label">Carpet Area</span>
                  <span className="property-overview-value">
                    {selectedproperty?.carpet_area}
                  </span>
                </div>}
                {selectedproperty?.property_on_floor && <div className="property-overview-items">
                  <span className="property-overview-label">
                    Property on Floor
                  </span>
                  <span className="property-overview-value">
                    {selectedproperty?.property_on_floor}
                  </span>
                </div>}
                {selectedproperty?.occupancy_status && <div className="property-overview-items">
                  <span className="property-overview-label">
                    Occupancy Status
                  </span>
                  <span className="property-overview-value">
                    {selectedproperty?.occupancy_status}
                  </span>
                </div>}
                {selectedproperty?.property_facing && <div className="property-overview-items">
                  <span className="property-overview-label">
                    Property Facing
                  </span>
                  <span className="property-overview-value">
                    {selectedproperty?.property_facing}
                  </span>
                </div>}
                {selectedproperty?.property_established && <div className="property-overview-items">
                  <span className="property-overview-label">Built in</span>
                  <span className="property-overview-value">
                    {selectedproperty?.property_established}
                  </span>
                </div>}
                {selectedproperty?.residential_parking && <div className="property-overview-items">
                  <span className="property-overview-label">Car Parking</span>
                  <span className="property-overview-value">
                    {selectedproperty?.residential_parking}
                  </span>
                </div>}
                {selectedproperty?.lift && <div className="property-overview-items">
                  <span className="property-overview-label">Lifts</span>
                  <span className="property-overview-value">
                    {selectedproperty?.lift}
                  </span>
                </div>}
              </div>
              {selectedproperty?.amenities?.length > 0 && <div className="property-amenties">
                <div className="property-overview property-box-border">
                  <div className="property-overview-title">Amenities</div>
                  {selectedproperty?.amenities?.map((item) => (
                    <div className="property-amenties-items">
                      <span className="property-amenties-value">{item}</span>
                    </div>
                  ))}
                </div>
              </div>}

              {selectedproperty?.property_sale_type === 'Rent' && <div className=" property-rent-details">
                  {selectedproperty?.security_deposit && <div className="property-rent-row">
                    <span className="property-rent-details-label">Security Deposit</span>
                    <span className="property-rent-details-value">₹ {selectedproperty?.security_deposit}</span>
                  </div>}
                  {selectedproperty?.maintenance_amount && <div className="property-rent-row">
                    <span className="property-rent-details-label">Maintenance Amount</span>
                    <span className="property-rent-details-value">₹ {selectedproperty?.maintenance_amount}</span>
                  </div>}
                  {selectedproperty?.lock_in_period && <div className="property-rent-row">
                    <span className="property-rent-details-label">Lock-in Period</span>
                    <span className="property-rent-details-value">{selectedproperty?.lock_in_period}</span>
                  </div>}
                  {selectedproperty?.tenant_preferences?.length > 0  && <div className="property-rent-row">
                    <span className="property-rent-details-label">Tenant Preferences</span>
                    {selectedproperty?.tenant_preferences?.map((item,index)=>(
                      <span className="property-rent-details-value">{item} {index < selectedproperty?.tenant_preferences?.length ? ',':''}</span>
                    ))} 
                  </div>}
              </div>}

              {selectedproperty?.documents?.length >0 && <div className="property-docs">
                <div className="property-overview property-box-border">
                  <div className="property-overview-title">Document</div>
                  {selectedproperty?.documents?.map((item) => (
                    <div
                      className="property-docs-items property-box-border"
                      onClick={() => handlePdfClick(item.file_url)}
                    >
                      <img src={PDFICON} alt="pdf-icon" width={30} />
                      <span className="property-doc-name">
                        {item.file_name}
                      </span>
                      <span className="property-doc-size">
                        {item.file_size}
                      </span>
                    </div>
                  ))}
                </div>
              </div>}
              {/* <div className='property-liv-score property-box-border'>
            <div className='property-overview-title'>Liveability Score</div>
            <CircularProgress color='success' variant="determinate" value={55} />
           </div> */}
            </div>
          )}
          <div className="property-detail-footer">
            {loading ? (
              <Skeleton width={100} height={50} />
            ) : (
              <Button
                type="submit"
                variant="contained"
                className="property-detail-btn"
                color="primary"
                disabled
              >
                Unlock to chat
              </Button>
            )}
          </div>
        </Box>
      </Drawer>
    </div>
  );
}
