import React, { useState } from "react";
import SAVEICON from "../Assets/images/save-icon.png";
import UNSAVEICON from "../Assets/images/unsave-icon.png";
import VIEW from "../Assets/images/view-icon.png";
import { numDifferentiation } from "../Utils/helperUtils";
import apis from "../Services/api-services";

const PropertyListItem = (props) => {
  const {
    index,
    showDays,
    parentClasses = "",
    cover_picture,
    property_status,
    property_sale_type,
    property_title,
    locality,
    views,
    sale_price,
    rent_amount,
    built_up_area,
    bedroom_count,
    setOpenPropertyDetail,
    fromSaved=false,
    handleSelectedProperty,
    property_id
  } = props;
  const [isSave, setIsSave] = useState(fromSaved);



  const handleWishList  = () =>{

    apis.property.wishList({property_id},!isSave ? 'POST':'DELETE')
    .then((response)=>{
        console.log(response)
        
        setIsSave(!isSave)
    })
    .then((err)=>{
      console.log(err, 'Saving property')
    })
    setIsSave(!isSave)

  }



  return (
    <div className={`property-item ${parentClasses || ""}`} onClick={(e)=>handleSelectedProperty(property_id,e.target)}>
      <div className="property-item-image-container">
        <img
          src={cover_picture}
          alt="property-image"
          className="property-item-img"
        />
        {
          <>
            {showDays ? (
          <div className="show-days" style={{ background: "#EBF4DF" }}>
            {property_status}
          </div>
        ) : (
          <div
            className="property-item-save"
            id="property-item-save"
            onClick={() => handleWishList()}
          >
            <img src={isSave ? SAVEICON : UNSAVEICON} alt="save"
            id="property-item-save"
            width={14} />
          </div>
        )}
          </>
        }
      
        <div className="property-item-price">
          {sale_price ? numDifferentiation(sale_price) : numDifferentiation(rent_amount) } 
          {property_sale_type === 'Rent' && ' p.m'}
        </div>
      </div>
      <div className="property-item-name">
        <span>{property_title}</span>
        {showDays && (
          <span className="property-item-views">
            <img src={VIEW} alt="view" className="property-item-icon" />
            <span>{views} Views</span>
          </span>
        )}
      </div>
      <div className="property-item-built-up">{built_up_area} sq. ft. | {bedroom_count} BHK | {property_sale_type}</div>
      <div className="property-item-location">{locality}</div>

    </div>
  );
};

export default PropertyListItem;
