import requestServer from '../request-server';

let internals = {};

internals.getCountries = function () {
  return requestServer(
    'v1/api/country',
    {},
    'GET',
    {},
    'application/json',
    true
  );
};

internals.getStates = function (countryCode) {
  return requestServer(
    `v1/api/state?country_code=${countryCode}`,
    {},
    'GET',
    {},
    'application/json',
    true
  );
};

internals.getCities = function (countryCode, stateCode) {
  return requestServer(
    `v1/api/city?country_code=${countryCode}&state_code=${stateCode}`,
    {},
    'GET',
    {},
    'application/json',
    true
  );
};

internals.getPropertyDropdownValues = function () {
  return requestServer(
    `v1/api/property_dropdown_values`,
    {},
    'GET',
    {},
    'application/json',
    true
  );
};

export default internals;
