import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
};

export const propertySlice = createSlice({
  name: 'property',
  initialState,
  reducers: {},
});

export const propertyActions = propertySlice.actions;

export default propertySlice.reducer;
