import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoggedIn: false,
};

export const dropdownSlice = createSlice({
  name: 'dropdown',
  initialState,
  reducers: {},
});

export const dropdownActions = dropdownSlice.actions;

export default dropdownSlice.reducer;
