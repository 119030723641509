import React, { useEffect, useRef, useState } from "react";
import AIRARECT from "../../Assets/images/Aira_Logo_purple.png";
import HERO from "../../Assets/images/home-background.png";
import SEARCHICON from "../../Assets/images/search-icon.png";
import "./home.css";
import {
  Box,
  Button,
  InputAdornment,
  OutlinedInput,
  Slider,
  TextField,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import PropertyList from "../../components/propertyList";
import apis from "../../Services/api-services";
import { useNavigate } from "react-router-dom";
import { CHAT_WITH_AIRA } from "../../constants/route-path";
import { numDifferentiation } from "../../Utils/helperUtils";

const maxSaleValue = 1000000;
const minSaleValue = 1000000000;
const maxRentValue = 50000;
const minRentValue = 1000000

const RenderDropDown = ({ Label , list=[] ,value, setState }) => {

  const handleChange = (event) => {
    setState(event.target.value);
  };
  return (
    <FormControl
      sx={{
        m: 1,
        width: "110px",
        borderRadius: "8px",
        background: "rgba(244, 244, 244, 1)",
        border: "none",
        "& fieldset": {
          border: "none",
          fontSize: "10px",
        },
        "& .MuiOutlinedInput-input": {
          fontSize: "14px",
        },
      }}
      size="small"
    >
      <Select
        value={value} 
        onChange={handleChange}
        autoWidth={false}
      >
        <MenuItem value={'null'} disabled>
          {Label}
        </MenuItem>
        {list.map((item)=>{
        return (<MenuItem value={item.value}>{item.value}</MenuItem>)
        })}
      </Select>
    </FormControl>
  );
};

const Home = () => {
  const propertyRef =  useRef()
  const navigate =  useNavigate();
  const [propertyList,setPropertyList] =  useState([])
  const [dropDownValues,setDropDownValue] =  useState({})
  const [propertyType,setPropertyType] =  useState('null')
  const [houseType,setHouseType] =  useState('null')
  const [furnishType,setFurnishType] =  useState('null')
  const [bedrooms,setBedRooms] =  useState('null')
  const [location,setLocation] =  useState('')
  const [budgetRange, setbudgetRange] = useState([1120000, 500000000]);
  // const [minBudget,setMinBudget] =  useState(minRentValue)
  // const [maxBudget,setMaxBudget] =  useState(maxRentValue)




  const handleChange = (event, newValue) => {
    setbudgetRange(newValue);
  };
  function valuetext(value) {
    console.log(value);
    return `${value}`;
  }

  const propertyProps = {
    dropDownValues,propertyType,setPropertyType ,houseType,setHouseType , furnishType,setFurnishType,
    bedrooms,setBedRooms, location,setLocation, budgetRange, setbudgetRange , setPropertyList
  }

  // useEffect(()=>{
  //   if(houseType === 'Rent'){
  //     setMinBudget(minRentValue)
  //     setMaxBudget(maxRentValue)
  //   }else if(houseType=== 'Sale'){
  //     setMinBudget(minSaleValue)
  //     setMaxBudget(maxSaleValue)
  //   }
  // },[houseType])



// const buildQueryParams = (params) => {
//   return Object.keys(params)
//     .filter((key) => params[key]) // Filter out empty values
//     .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
//     .join('&');
// };

// const fetchFilteredData = async (filters) => {
//   const baseUrl = 'https://aira-apis.indiassetz.com/v1/user/recommendations';
//   const queryParams = buildQueryParams(filters);
// };

useEffect(()=>{
  apis.property.getDropDownValues()
  .then((response)=>{
    setDropDownValue(response.data)
    console.log(response.data,'ressss')
  })
  .catch((err)=>console.log('Error fetching dropdown values',err))
},[])


const sanitizeData = (data)=>{
 return data !== '' && data !== 'null';
}



  const handlePropertyDisplay = ()=> {
    // setShowProperty(true)

    const filters = {
      ...(sanitizeData(location) ? {locality: location} : {}),
      ...(sanitizeData(houseType) ? {property_type: houseType} : {}),
      ...(sanitizeData(bedrooms) ? {bedroom: bedrooms} : {}),
      ...(sanitizeData(furnishType) ? {furnishing: furnishType} : {}),
      ...(sanitizeData(propertyType) ? {list_type:propertyType} : {}),
      // minimum_built_up_area: 1200,
      // maximum_built_up_area: 2000,
      // availability: "2024-10-03",
      minimum_budget: budgetRange[0],
      maximum_budget: budgetRange[1]
    };
    
    apis.property.getRecommendations(filters)
    .then((response)=>{
      console.log(response,'smjbsj')
      setPropertyList(response.data.recommendations)
    })
    .catch((err)=>{

    })

    // propertyRef.current?.scrollIntoView({behavior: 'smooth'});
    // setTimeout(() => {
    //   setShowHome(false)
    // }, 1000);
  }
  return (
    
      <>
       {propertyList?.length < 1 ? 
       <div className="home-container">
      {/* <Navbar /> */}

      <div className="home-hero-section">
        <div className="home-hero-image">
          <img src={HERO} alt="hero-image" className="hero-image" />
        </div>
        <div className="home-hero-title">Unlock Your Door to Dream Living</div>
        <div className="home-search-filter">
          <div className="home-search-inputs">
            <div className="home-search-input-row">
              <RenderDropDown Label="List Type" list={dropDownValues?.list_type} value={propertyType} setState={setPropertyType} />
              <Box sx={{ width: '70%', marginX:2 }}>

              <FormControl
                size="small"
                sx={{
                  m: 1,
                  width:'95%',
                  marginRight:3,
                  borderRadius: "8px",
                  background: "rgba(244, 244, 244, 1)",
                  border: "none",
                  "& fieldset": {
                    border: "none",
                  },
                  "& .MuiOutlinedInput-input": {
                    fontSize: "14px",
                  },
                }}
              >
                <OutlinedInput
                  placeholder="Search by location"
                  value={location}
                  onChange={(e)=>setLocation(e.target.value)}
                  startAdornment={
                    <InputAdornment position="start">
                      <img
                        src={SEARCHICON}
                        alt="search"
                        width={35}
                        height={35}
                      />
                    </InputAdornment>
                  }
                />
              </FormControl>
              </Box>
              <RenderDropDown Label="House" list={dropDownValues?.property_types} value={houseType} setState={setHouseType}  />
            </div>
            <div className="home-search-input-row search-filter-2">
              <span className="home-budget-label"> Budget </span>
              <Box sx={{ width: '70%', marginX:2, marginLeft:3 }}>
                <Slider
                  getAriaLabel={() => "Price Range"}
                  value={budgetRange}
                  onChange={handleChange}
                  valueLabelDisplay="on"
                  getAriaValueText={valuetext}
                  min={10000}
                  max={1000000000}
                  valueLabelFormat={(value)=>numDifferentiation(value)}
                  sx={{
                      width:'97%',
                    ".MuiSlider-thumb": {
                      width: "13px",
                      height: "13px",
                    },
                    ".MuiSlider-valueLabel": {
                      background: "#F5EDFF",
                      color: "#7D7D7D",
                      borderRadius: "30px",
                    },
    
                  }}
                />
              </Box>
              <RenderDropDown Label="Bed Rooms" list={dropDownValues?.bedrooms} value={bedrooms} setState={setBedRooms} />
            </div>
          </div>
          <div className="home-search-btn">
            <Button
              variant="contained"
              color="primary"
              sx={{
                height: "100%",
                textTransform: "none",
                paddingX: 5,
              }}
              onClick={() => handlePropertyDisplay()}
            >
              Find
            </Button>
          </div>
        </div>
      </div>
      <div className="home-chat-aira-btn" >
        <img src={AIRARECT} alt="aira-logo" onClick={() => navigate(CHAT_WITH_AIRA)} className="home-aira-rect" />
      </div>
    </div>
    :
    <PropertyList from='home' data={propertyList} propertyRef={propertyRef} {...propertyProps}  />
    } 
    </>
  );
};

export default Home;
