import React, { useState } from "react";
import PropertyListItem from "./propertyListItem";
import "./propertyList.css";
import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import SEARCHICON from "../Assets/images/search-icon.png";
import ProperyDetail from "./propertyDetail";
import apis from "../Services/api-services";

const RenderDropDown = ({ Label, list = [], value, setState }) => {
  const handleChange = (event) => {
    setState(event.target.value);
  };
  return (
    <FormControl
      sx={{
        m: 1,
        minWidth: 100,
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderRadius: "16px",
        },

        "& .MuiOutlinedInput-input": {
          fontSize: "14px",
        },
      }}
      size="small"
    >
      <Select value={value} onChange={handleChange} autoWidth={false}>
        <MenuItem value={"null"} disabled>
          {Label}
        </MenuItem>
        {list.map((item) => {
          return <MenuItem value={item.value}>{item.value}</MenuItem>;
        })}
      </Select>
    </FormControl>
  );
};

const PropertyList = (props) => {
  const {
    hideFilter = false,
    showDays,
    parentClasses,
    propertyRef,
    data = [],
    dropDownValues,
    propertyType,
    setPropertyType,
    houseType,
    setHouseType,
    furnishType,
    setFurnishType,
    bedrooms,
    setBedRooms,
    location,
    setLocation,
    budgetRange,
    setbudgetRange,
    setPropertyList,
    from
  } = props;
  const [openPropertyDetail, setOpenPropertyDetail] = useState(false);
  const [selectedPropertyID, setSelectedPropertyID] = useState(null);

  console.log(data, "ssss");

  const sanitizeData = (data)=>{
    return data !== '' && data !== 'null';
   }


  const handlePropertyDisplay = ()=> {
    // setShowProperty(true)

    const filters = {
      ...(sanitizeData(location) ? {locality: location} : {}),
      ...(sanitizeData(houseType) ? {property_type: houseType} : {}),
      ...(sanitizeData(bedrooms) ? {bedroom: bedrooms} : {}),
      ...(sanitizeData(furnishType) ? {furnishing: furnishType} : {}),
      ...(sanitizeData(propertyType) ? {list_type:propertyType} : {}),
      // minimum_built_up_area: 1200,
      // maximum_built_up_area: 2000,
      // availability: "2024-10-03",
      minimum_budget: budgetRange[0],
      maximum_budget: budgetRange[1]
    };
    
    apis.property.getRecommendations(filters)
    .then((response)=>{
      setPropertyList(response.data.recommendations)
    })
    .catch((err)=>{

    })

    // propertyRef.current?.scrollIntoView({behavior: 'smooth'});
    // setTimeout(() => {
    //   setShowHome(false)
    // }, 1000);
  }

  const toggleDrawer = (newOpen) => () => {
    setOpenPropertyDetail(newOpen);
  };

  const handleSelectedProperty = (id, target) => {
    if (target?.id !== "property-item-save") {
      setSelectedPropertyID(id);
      setOpenPropertyDetail(true);
    }
  };

  return (
    <div className="property-list-container" ref={propertyRef}>
      {!hideFilter && (
        <div className="property-list-filters">
          <div className="property-list-filters-content">
            <Box sx={{ width: "30%" }}>
              <FormControl
                size="small"
                sx={{
                  m: 1,
                  width: "95%",
                  marginRight: 3,
                  borderRadius: "16px",
                  "& fieldset": {
                    borderRadius: "16px",
                  },
                  "& .MuiOutlinedInput-input": {
                    fontSize: "14px",
                  },
                }}
              >
                <OutlinedInput
                  placeholder="Search by location"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  startAdornment={
                    <InputAdornment position="start">
                      <img
                        src={SEARCHICON}
                        alt="search"
                        width={35}
                        height={35}
                      />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Box>
            <RenderDropDown
              Label="Bedrooms"
              list={dropDownValues?.bedrooms}
              value={bedrooms}
              setState={setBedRooms}
            />
            <RenderDropDown
              Label="Type"
              list={dropDownValues?.list_type}
              value={propertyType}
              setState={setPropertyType}
            />
            <RenderDropDown
              Label="House"
              list={dropDownValues?.property_types}
              value={houseType}
              setState={setHouseType}
            />
            <RenderDropDown
              Label="Furnish"
              list={dropDownValues?.furnish_types}
              value={furnishType}
              setState={setFurnishType}
            />
            <Button
              type="submit"
              sx={{
                width: 100,
                borderRadius: "16px",
                background: "#fffff",
                border: "1px solid ",
                fontSize: "14px",
              }}
              onClick={handlePropertyDisplay}
            >
              Find
            </Button>
          </div>
        </div>
      )}
      {data?.length && data.length > 0 ? 
      <div className="property-list-items">
      {data.map((item, index) => (
        <PropertyListItem
          showDays={showDays}
          key={index}
          {...item}
          index={index}
          parentClasses={parentClasses}
          handleSelectedProperty={handleSelectedProperty}
          fromSaved={props.fromSaved}
        />
      ))}
      </div>
      :
      <div className="no-properties">No Data To show</div>  
    }
    
      <ProperyDetail
        selectedPropertyID={selectedPropertyID}
        toggleDrawer={toggleDrawer}
        open={openPropertyDetail}
        setOpen={setOpenPropertyDetail}
        from={from}
      />
    </div>
  );
};

export default PropertyList;
