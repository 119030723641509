import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import PropertyList from '../../components/propertyList';
import './dashboard.css';
import apis from '../../Services/api-services';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Dashboard() {
  const [value, setValue] = useState(0);
  const [listedProperties, setListedProperties] = useState([]);
  const [savedProperties, setSavedProperties] = useState([]);

  useEffect(() => {
    fetchListedProperties();
    fetchSavedProperties();
  }, []);

 useEffect(()=>{
 value && fetchSavedProperties()
 },[value])

  const fetchListedProperties = () => {
    apis.property
      .getListingProperties()
      .then((data) => {
        setListedProperties(data?.data?.properties);
      })
      .catch((err) => {
        console.log('Failed to fetch Listed Properties ', err);
      });
  };

  const fetchSavedProperties = () => {
    apis.property
      .getSavedProperties()
      .then((data) => {
        setSavedProperties(data?.data?.wishlist);
      })
      .catch((err) => {
        console.log('Failed to fetch Saved Properties ', err);
      });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', padding: '20px 30px' }}>
      <Box sx={{ paddingLeft: '20px' }} className="dashboard-tabs-container">
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          className='dashboard-tabs'
        >
          <Tab
            label="Your Listings"
            className="dashboard-tab"
            {...a11yProps(0)}
          />
          <Tab label="Saved" className="dashboard-tab" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <PropertyList
          from="listing"
          data={listedProperties}
          hideFilter={true}
          showDays={true}
          parentClasses="width1-3"
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <PropertyList
          from="saved"
          data={savedProperties}
          fromSaved={true}
          hideFilter={true}
          parentClasses="width1-3"
        />
      </CustomTabPanel>
    </Box>
  );
}
