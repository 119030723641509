import React, { useState } from "react";
import BRANDLOGO from "../Assets/images/brand-logo.png";
import PROFILE from "../Assets/images/nav-profile.png";
import NOTIFY from "../Assets/images/nav-nofication.png";
import LOGOUT_ICON from "../Assets/images/logout.svg";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import "./navbar.css";
import { ADD_PROPERTY, DASHBOARD, HOME } from "../constants/route-path";
import { Box, Fade, Popover, Popper } from "@mui/material";

const Navbar = ({ handleLogout , selectedNavLink, setSelectedNavLink }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const navlinks = [
    {
      title: "Home",
      id: 1,
      to: HOME,
    },
    {
      title: "Dashboard",
      id: 2,
      to: DASHBOARD,
    },
    // {
    //     title:'Messages',
    //     id:3,
    //     to:MESSAGES
    // }
  ];

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <nav className="navbar">
      <div className="nav-brand">
        <img src={BRANDLOGO} alt="logo" className="nav-brand-img" onClick={()=>navigate(HOME)} />
      </div>
      <div className="nav-links-main">
        <div className="nav-links-content">
          {navlinks.map((item) => (
            <NavLink
              onClick={() => setSelectedNavLink(item.id)}
              to={item.to}
              className={`nav-links ${
                selectedNavLink === item.id ? "active-nav-link" : ""
              }`}
            >
              {" "}
              {item.title}{" "}
            </NavLink>
          ))}
        </div>
      </div>
      <div className="nav-buttons">
        {pathname !== ADD_PROPERTY && (
          <span
            className="nav-list-property-btn"
            onClick={() => navigate("/add-property")}
          >
            <AddIcon className="nav-add-icon" />
            List my Property
          </span>
        )}

        <span
          className="nav-button"
          onClick={handleClick}
          aria-describedby={id}
        >
          <img src={PROFILE} alt="profile" />
        </span>
        <span className="nav-button">
          <img src={NOTIFY} alt="notification" />
        </span>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          sx={{
            ".MuiBox-root": {
              border: "none",
              borderRadius: "10px",
              boxShadow: "1px 1px 7.3px 0px rgba(185, 185, 185, 0.25)",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            },
          }}
          className="home-profile-content"
        >
          <Box
            onClick={() => handleLogout()}
            sx={{
              border: 1,
              p: 2,
              bgcolor: "background.paper",
              cursor: "pointer",
            }}
          >
            Logout{" "}
            <img src={LOGOUT_ICON} alt="logout" className="home-logout-icon" />
          </Box>
        </Popover>
      </div>
    </nav>
  );
};

export default Navbar;
